
  <template>
  <div class="about padding-top-lg margin-bottom-tab screen padding-lr-sm">
    <!-- <van-cell class="padding-lr-0" title="我的团队" value="内容" /> -->
    <div class="flex align-center margin-bottom">
      <van-icon size="small" name="arrow-left" />
      <div class="back text-df text-bold" @click="goBack">返回</div>
    </div>
    <div class="flex align-center padding-top justify-between">
      <div class="title text-lg text-bold margin-bottom">互评设置</div>
      <van-button
        v-if="status != 3"
        type="info"
        size="small"
        @click="updateDepartments"
        >刷新人员</van-button
      >
    </div>
    <div class="top padding-tb">
      <div class="text-left text-df">请对团队成员每人设置至少5个互评人</div>
    </div>
    <div v-for="(item, index) in departments" :key="index">
      <div
        class="flex align-center padding-top solid-bottom padding-bottom margin-bottom-sm"
      >
        <div class="point bg-blue margin-right-xs"></div>
        <div class="title text-df">{{ item.dept_name }}</div>
      </div>
      <div
        class="flex flex-direction solid-bottom padding-bottom-sm margin-bottom-sm"
        v-for="(iitem, iindex) in item.members"
        :key="'member' + iindex"
      >
        <div
          class="padding-sm flex align-center justify-between margin-bottom-xs"
        >
          <div class="flex justify-start align-center">
            <div class="title text-df margin-right-sm">{{ iitem.name }}</div>
            <div class="flex justify-start align-center" v-if="status < 3">
              <van-button
                v-if="iitem.checked"
                round
                type="info"
                size="mini"
                @click="inviteUsers(index, iindex, iitem)"
                >添加互评人</van-button
              >
              <van-button
                v-if="iitem.checked"
                round
                type="warning"
                size="mini"
                @click="addLeader(index, iindex, iitem)"
                >添加{{ iitem.gender == 0 ? "她" : "他" }}的上级</van-button
              >
            </div>
          </div>
          <div class="flex justify-start align-center">
            <div
              class="flex justify-start align-center margin-right-sm"
              v-if="status < 2"
            >
              <div class="text-sm margin-right-sm">参与自评</div>
              <van-switch
                :disabled="status != 3 ? false : true"
                v-model="iitem.evaluate_self"
                size="20px"
                @input="
                  toggleEvaluateSelfSwitch(index, iindex, iitem.evaluate_self)
                "
              />
            </div>
            <div class="flex justify-start align-center">
              <div class="text-sm margin-right-sm">参与互评</div>
              <van-switch
                :disabled="status != 3 ? false : true"
                v-model="iitem.checked"
                size="20px"
                @input="toggleUserSwitch(index, iindex, iitem.checked)"
              />
            </div>
          </div>
        </div>
        <div class="response" v-if="iitem.checked">
          <van-tag
            v-for="(inviter, inviterIndex) in iitem.inviteUsersBySelf"
            :key="'inviter' + inviterIndex"
            class="margin-rb-sm padding-sm fl"
            plain
            size="mini"
            type="success"
          >
            {{ inviter.name }}
          </van-tag>
          <div class="flex justify-start align-center" v-if="status != 3">
            <van-tag
              v-for="(userItem, userIndex) in iitem.inviteUsers"
              :key="'user' + userIndex"
              class="margin-rb-sm padding-sm fl"
              closeable
              plain
              size="mini"
              type="primary"
              @close="deleteInviteUser(index, iindex, userIndex, userItem)"
            >
              {{ userItem.name }}
            </van-tag>
            <van-tag
              v-for="(leaderItem, leaderIndex) in iitem.leader"
              :key="'leader' + leaderIndex"
              class="margin-rb-sm padding-sm fl"
              closeable
              plain
              size="mini"
              type="warning"
              @close="deleteLeader(index, iindex, leaderItem)"
            >
              {{ leaderItem.name }}
            </van-tag>
          </div>
          <div class="flex justify-start align-center" v-else>
            <van-tag
              v-for="(userItem, userIndex) in iitem.inviteUsers"
              :key="'user' + userIndex"
              class="margin-rb-sm padding-sm fl"
              plain
              size="mini"
              type="primary"
            >
              {{ userItem.name }}
            </van-tag>
            <van-tag
              v-for="(leaderItem, leaderIndex) in iitem.leader"
              :key="'leader' + leaderIndex"
              class="margin-rb-sm padding-sm fl"
              plain
              size="mini"
              type="warning"
            >
              {{ leaderItem.name }}
            </van-tag>
          </div>
        </div>
      </div>
    </div>
    <van-tabbar v-model="tab_active" route>
      <van-tabbar-item icon="bars" replace to="/result">结果</van-tabbar-item>
      <van-tabbar-item icon="setting" replace to="/settings"
        >维度</van-tabbar-item
      >
      <van-tabbar-item icon="user" replace to="/user">人员</van-tabbar-item>
    </van-tabbar>
  </div>
</template>
  
  <script>
import * as dd from "dingtalk-jsapi";
import {
  toggleUserSwitch,
  deleteInviteUserAction,
  inviteUsersAction,
  addLeaderAction,
  deleteLeaderAction,
  getResultSettingsAction,
  toggleEvaluateSelfSwitchAction,
} from "../api/evaluation.js";

import {
  getPersonListAction,
  getDepartmentsAction,
  updateDepartmentsAction,
} from "../api/getCompanyInfo.js";

import Vue from "vue";
import {
  Tab,
  Tabs,
  Image,
  Divider,
  Cell,
  CellGroup,
  Switch,
  Button,
  Icon,
  Tag,
  Dialog,
} from "vant";

Vue.use(Dialog);
Vue.use(CellGroup);
Vue.use(Cell);
Vue.use(Divider);
Vue.use(Image);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Button);
Vue.use(Icon);
Vue.use(Tag);
Vue.use(Switch);

export default {
  name: "Home",
  components: {
    // Button,
    // DropdownMenu,
    // Popover,
    // BizCalendarChooseDateTime,
    // BizContactComplexPicker,
  },
  methods: {
    updateDepartments() {
      let that = this;
      let result_id = that.result_id;
      updateDepartmentsAction({ result_id: result_id }).then((res) => {
        let errno = res.errno;
        if (errno == 0) {
          that.$message({
            type: "success",
            message: "更新成功",
          });
          that.getDepartments();
        } else {
          that.$message({
            type: "error",
            message: res.errmsg,
          });
        }
      });
    },
    toggleEvaluateSelfSwitch(a, b, val) {
      let departments = this.departments;
      let members = departments[a].members;
      let member = members[b];
      member.evaluate_self = val;
      let that = this;
      that.departments = [];
      that.departments = departments;
      toggleEvaluateSelfSwitchAction({
        id: member.id,
        evaluate_self: val,
      }).then((res) => {
        let errno = res.errno;
        if (errno == 0) {
          that.$message({
            type: "success",
            message: "更新成功",
          });
          that.getDepartments();
        } else {
          that.$message({
            type: "error",
            message: res.errmsg,
          });
        }
      });
    },
    toggleUserSwitch(a, b, val) {
      let departments = this.departments;
      let members = departments[a].members;
      let member = members[b];
      let status = this.status;
      member.checked = val;
      let that = this;
      that.departments = [];
      that.departments = departments;
      toggleUserSwitch({
        id: member.id,
        checked: val,
        status: status,
      }).then((res) => {
        let errno = res.errno;
        if (errno == 0) {
          that.$message({
            type: "success",
            message: "更新成功",
          });
          that.getDepartments();
        } else {
          that.$message({
            type: "error",
            message: res.errmsg,
          });
        }
      });
    },
    deleteInviteUser(a, b, c, item) {
      let result_id = this.result_id;
      let that = this;
      Dialog.confirm({
        title: "提示",
        message: "确定删除?",
      })
        .then(() => {
          let departments = that.departments;
          let members = departments[a].members;
          let inviteUsers = members[b].inviteUsers;
          inviteUsers.splice(c, 1);
          that.departments = [];
          that.departments = departments;
          deleteInviteUserAction({
            result_id: result_id,
            userid: members[b].userid,
            item: item,
          }).then((res) => {
            let errno = res.errno;
            if (errno == 0) {
              that.$message({
                type: "success",
                message: "删除成功",
              });
            } else {
              that.$message({
                type: "error",
                message: res.errmsg,
              });
            }
          });
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },
    deleteLeader(a, b, item) {
      let departments = this.departments;
      let members = departments[a].members;
      let result_id = this.result_id;
      let that = this;
      Dialog.confirm({
        title: "提示",
        message: "确定删除?",
      })
        .then(() => {
          members[b].leader = [];
          that.departments = [];
          that.departments = departments;
          deleteLeaderAction({
            result_id: result_id,
            userid: members[b].userid,
            item: item,
          }).then((res) => {
            let errno = res.errno;
            if (errno == 0) {
              that.$message({
                type: "success",
                message: "删除成功",
              });
            } else {
              that.$message({
                type: "error",
                message: res.errmsg,
              });
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    goBack() {
      this.$router.push("/");
    },
    getPersonList() {
      let that = this;
      let result_id = that.result_id;
      getPersonListAction({ result_id: result_id }).then((res) => {
        let errno = res.errno;
        let data = res.data;
        if (errno == 0) {
          let departments = that.departmentList;
          let members = data;
          const departmentMap = new Map(
            departments.map((dept) => [dept.dept_id, { ...dept, members: [] }])
          );
          // 将成员数据添加到对应的部门对象的members数组中
          members.forEach((member) => {
            if (departmentMap.has(member.top_dept_id)) {
              departmentMap.get(member.top_dept_id).members.push({
                ...member,
                checked: member.checked === 1, // 根据实际情况转换checked字段
                evaluate_self: member.evaluate_self === 1, // 根据实际情况转换checked字段
              });
            }
          });
          const result = Array.from(departmentMap.values());
          that.departments = result;
        } else {
          that.$message({
            type: "error",
            message: res.errmsg,
          });
        }
      });
    },
    getDepartments() {
      let that = this;
      getDepartmentsAction().then((res) => {
        let errno = res.errno;
        let data = res.data;
        if (errno == 0) {
          that.departmentList = data;
          that.getPersonList();
        } else {
          that.$message({
            type: "error",
            message: res.errmsg,
          });
        }
      });
    },
    onHandleClick2() {
      dd.biz.contact.complexPicker({
        corpId: window.__corpId__,
      });
    },
    addLeader(index, iindex, iitem) {
      let result_id = this.result_id;
      let departments = this.departments;
      let members = departments[index].members;
      let person = members[iindex];
      let that = this;
      dd.chooseStaffForPC({
        max: 1,
        corpId: "ding2ed665dfe133e896",
        limitTips: "选择人数不能超过1个",
        title: "选择上级管理者",
        showLabelPick: true,
        multiple: true,
        success: (res) => {
          if (res[0].emplId == iitem.userid) {
            that.$message({
              message: "不能选择自己为上级管理者",
              type: "error",
            });
            return false;
          }
          res = res.filter((item) => item.emplId !== iitem.userid);
          person.leader = res;
          that.departments = [];
          that.departments = departments;
          addLeaderAction({
            result_id: result_id,
            userid: person.userid,
            leader: res,
          }).then((res) => {
            let errno = res.errno;
            if (errno == 0) {
              that.$message({
                type: "success",
                message: "添加成功",
              });
              that.getDepartments();
            } else {
              that.$message({
                type: "error",
                message: res.errmsg,
              });
            }
          });
        },
        fail: (err) => {
          const errcode = err.errorCode;
          if (errcode == -1) {
            this.$message({
              message: "已取消",
              type: "error",
            });
          } else if (errcode == 7) {
            this.$router.push("/login");
          }
        },
        complete: () => {},
      });
    },
    inviteUsers(index, iindex, iitem) {
      // let href = location.href;
      let result_id = this.result_id;
      let departments = this.departments;
      let members = departments[index].members;
      let person = members[iindex];
      let inviteUsers = person.inviteUsers;
      let inviteUsersBySelf = person.inviteUsersBySelf;
      dd.chooseStaffForPC({
        corpId: "ding2ed665dfe133e896",
        title: "选择同事",
        showLabelPick: true,
        multiple: true,
        success: (res) => {
          // const { emplId, name, avatar } = res;
          res = res.filter((item) => item.emplId !== iitem.userid);
          if (inviteUsers == undefined) {
            inviteUsers = [];
          }
          let mergedArray;
          if (inviteUsers != undefined) {
            let oldArrIds = new Set(inviteUsers.map((item) => item.emplId));
            // 过滤掉 array 中与 oldArr 中 id 相等的项
            res = res.filter((item) => !oldArrIds.has(item.emplId));
            // 添加 oldArr 中不重复的项到 array 中
            mergedArray = res.concat(
              inviteUsers.filter(
                (item) => !res.some((aItem) => aItem.emplId === item.userid)
              )
            );
            // // 这里要和自己邀约的人去重
            if (inviteUsersBySelf != undefined) {
              mergedArray = mergedArray.filter(
                (item1) =>
                  !inviteUsersBySelf.some(
                    (item2) => item2.emplId === item1.emplId
                  )
              );
            }
          }
          let newInviteUsers = mergedArray;
          person.inviteUsers = newInviteUsers;
          let that = this;
          that.departments = [];
          that.departments = departments;
          inviteUsersAction({
            result_id: result_id,
            userid: person.userid,
            newInviteUsers: newInviteUsers,
          }).then((res) => {
            let errno = res.errno;
            if (errno == 0) {
              that.$message({
                type: "success",
                message: "添加成功",
              });
              that.getDepartments();
            } else {
              that.$message({
                type: "error",
                message: res.errmsg,
              });
            }
          });
        },
        fail: (err) => {
          const errcode = err.errorCode;
          if (errcode == -1) {
            this.$message({
              message: "已取消",
              type: "error",
            });
          } else if (errcode == 7) {
            this.$router.push("/login");
          }
        },
        complete: () => {},
      });
    },
    getResultSettings() {
      let result_id = this.result_id;
      getResultSettingsAction({
        result_id: result_id,
      }).then((res) => {
        let errno = res.errno;
        let data = res.data;
        if (errno == 0) {
          this.status = data.status;
        }
      });
    },
  },
  mounted() {
    let result_id = localStorage.getItem("result_id");
    this.result_id = result_id;
    this.getDepartments();
    this.getResultSettings();
  },
  data() {
    return {
      result_id: 0,
      tab_active: 2,
      departmentList: [],
      departments: [],
      status: 0,
    };
  },
};
</script>
  
  <style scoped lang="scss">
.back {
  cursor: pointer;
}
.screen {
  max-width: 600px;
  margin: auto;
}
::v-deep .van-cell__title {
  display: flex;
  justify-content: flex-start;
}
</style>
