<template>
  <div class="about screen">
    <div class="flex justify-center flex-direction align-center">
      <div class="text-lg text-center margin-tb padding-tb">
        欢迎来到艾力特评价助手
      </div>
      <van-button type="info" round size="normal" @click="goHome"
        >登 录</van-button
      >
      <!-- <el-button type="primary">主要按钮</el-button> -->
    </div>
  </div>
</template>

<script src="https://g.alicdn.com/dingding/h5-dingtalk-login/0.21.0/ddlogin.js"></script>
<script>
// STEP3：在需要的时候，调用 window.DTFrameLogin 方法构造登录二维码，并处理登录成功或失败的回调。
window.DTFrameLogin(
  {
    id: "self_defined_element",
    width: 300,
    height: 300,
  },
  {
    redirect_uri: encodeURIComponent("https://ddweb.elite-audio.com/#/"),
    client_id: "dingtnyie2bw8ejyzhax",
    scope: "openid",
    response_type: "code",
    // state: "xxxxxxxxx",
    prompt: "consent",
  },
  (loginResult) => {
    const { redirectUrl, authCode, state } = loginResult;
    // 这里可以直接进行重定向
    window.location.href = redirectUrl;
    // 也可以在不跳转页面的情况下，使用code进行授权
    console.log(authCode);
  },
  (errorMsg) => {
    // 这里一般需要展示登录失败的具体原因,可以使用toast等轻提示
    console.error(`errorMsg of errorCbk: ${errorMsg}`);
  }
);
</script>
<script>
import { login } from "../api/auth.js";
import { setToken } from "../utils/auth";
import * as dd from "dingtalk-jsapi";
// import dd from 'dingtalk-jsapi';
import api from "../api/api";

import Vue from "vue";
import { Button } from "vant";

Vue.use(Button);

export default {
  name: "Login",
  components: {},
  created() {
    this.getDingTalkAuthCode();
  },
  mounted() {},
  methods: {
    goHome() {
      this.getDingTalkAuthCode();
    },
    getDingTalkAuthCode() {
      // 调用钉钉JSAPI获取免登授权码
      const cid = this.corpId;
      let that = this;
      dd.ready(() => {
        dd.getAuthCode({
          corpId: cid, // 替换为你的企业ID
          success: function (res) {
            that.handleAuthCode(res.code);
          },
          fail: function (err) {
            console.log("获取免登授权码失败:", err);
          },
        });
      });
    },
    handleAuthCode(code) {
      let that = this;
      login({ code: code }).then((res) => {
        let errno = res.errno;
        if (errno == 0) {
          const token = res.data.token;
          if (token != undefined) {
            setToken(token);
            const userInfo = JSON.stringify(res.data.userInfo);
            localStorage.setItem("userInfo", userInfo);
            const param = res.data.param;
            that.jsapiAuth(param);
          } else {
            that.$message({
              type: "error",
              message: "token为空",
            });
          }
        } else {
          that.$message({
            type: "error",
            message: res.errmsg,
          });
        }
      });
    },
    jsapiAuth(param) {
      dd.config({
        agentId: this.agentId, // 应用的agentId，需与后台配置一致
        corpId: this.corpId, // 企业id，需与后台配置一致
        timeStamp: param.timeStamp, // 时间戳，需与后台一致
        nonceStr: param.nonceStr, // 随机字符串，需与后台一致
        signature: param.signature, // 签名
        jsApiList: ["biz.contact.complexPicker", "chooseStaffForPC"], // 需要使用的钉钉JSAPI列表
      });
      this.$router.push("/");
    },
  },
  data() {
    return {
      corpId: api.corpId,
      code: "",
    };
  },
};
</script>

<style scoped lang="scss">
.score-width {
  width: 20px;
}
.screen {
  max-width: 600px;
  margin: auto;
}
</style>