<template>
  <div class="about screen padding-top">
    <div class="padding-lr-sm">
      <div class="flex align-center margin-bottom-sm">
        <van-icon size="small" name="arrow-left" />
        <div class="back text-df text-bold" @click="goBack">返回</div>
      </div>
      <div class="flex justify-between align-center padding-tb">
        <div class="title text-lg text-bold margin-bottom">评价详情</div>
        <div class="flex justify-start align-center">
          <van-button
            v-if="status == 2 && scoreData.status == 3"
            class="margin-right"
            type="warning"
            round
            size="small"
            @click="resetAndMessage"
            >重启评价并通知</van-button
          >

          <!-- TODO 导出数据 -->
          <van-button
            type="info"
            round
            size="small"
            @click="exportPersonEvaluate"
            >导出数据</van-button
          >
        </div>
      </div>
      <div
        class="flex justify-between padding-bottom-sm margin-bottom-sm solid-bottom"
      >
        <div class="flex justify-start align-center">
          <div class="name text-xxl text-bold margin-right">
            {{ scoreData.name }}
          </div>
          <div
            v-if="scoreData.rank == 1"
            class="rank bg-green margin-right-sm"
          ></div>
          <div
            v-if="scoreData.rank == 2"
            class="rank bg-yellow margin-right-sm"
          ></div>
          <div
            v-if="scoreData.rank == 3"
            class="rank bg-red margin-right-sm"
          ></div>
          <div class="text-df">{{ scoreData.score_text }}</div>
        </div>
        <div class="flex align-center padding-sm">
          <van-rate
            class="margin-right-sm"
            allow-half
            :size="20"
            v-model="scoreData.final_score"
            readonly
          />
          <div
            class="text-lg text-bold score-width2"
            v-if="scoreData.final_score != null"
          >
            {{ scoreData.final_score }}分
          </div>
        </div>
      </div>
      <div class="flex justify-between padding-bottom-sm margin-bottom-sm">
        <div class="name text-sm">综合评价</div>
        <div class="flex align-center">
          <van-rate
            allow-half
            :size="16"
            v-model="scoreData.score"
            class="margin-right-sm"
            readonly
          />
          <div class="text-df score-width" v-if="scoreData.score != null">
            {{ scoreData.score }}分
          </div>
        </div>
      </div>
      <div
        class="flex flex-direction padding-bottom-sm margin-bottom-sm solid-bottom"
      >
        <div class="flex justify-between">
          <div class="name text-sm">人事评价</div>
          <div class="flex align-center">
            <van-rate
              allow-half
              :size="16"
              v-model="scoreData.hr_score"
              readonly
              class="margin-right-sm"
            />
            <div class="text-df score-width" v-if="scoreData.hr_score != null">
              {{ scoreData.hr_score }}分
            </div>
            <van-button
              class="margin-left-sm"
              v-if="status != 3"
              icon="edit"
              type="info"
              size="mini"
              @click="showDialogOne"
            />
          </div>
        </div>
        <div class="response text-sm text-left text-gray">
          {{ scoreData.hr_memo }}
        </div>
      </div>
    </div>
    <van-sticky>
      <div class="screen">
        <van-tabs v-model="active" class="solid-bottom" @click="onClick">
          <van-tab title="自我评价"></van-tab>
          <van-tab title="同事给我的评价"></van-tab>
          <van-tab title="我给同事的评价"></van-tab>
          <van-tab title="对上级的评价"></van-tab>
          <van-tab title="下属的评价" v-if="isLeader == 1"></van-tab>
        </van-tabs>
      </div>
    </van-sticky>
    <div v-if="active == 0">
      <div class="flex align-center justify-between padding-sm padding-top">
        <div class="flex align-center">
          <div class="point bg-red margin-right-xs"></div>
          <div class="title text-df">考核指标</div>
        </div>
        <div class="flex align-center">
          <van-rate allow-half :size="16" v-model="dimensionScore" readonly />
          <div class="text-lg score-width2" v-if="dimensionScore != null">
            {{ dimensionScore }}分
          </div>
        </div>
      </div>
      <div
        class="padding-sm flex align-center flex-direction justify-between"
        v-for="(item, index) in dimensionZero"
        :key="'type_one_a' + index"
      >
        <div
          class="response flex align-center justify-between margin-bottom-xs"
        >
          <div class="flex justify-start align-center">
            <div class="title text-df margin-right-sm">{{ item.title }}</div>
            <div class="text-df">{{ item.percent }}%</div>
          </div>
          <div class="flex align-center">
            <van-rate allow-half :size="16" v-model="item.score" readonly />
            <div class="text-df score-width" v-if="item.score != null">
              {{ item.score }}分
            </div>
          </div>
        </div>
        <div class="response text-sm text-left text-gray">
          {{ item.intro }}
        </div>
        <van-field
          class="textarea"
          readonly
          v-model="item.reason"
          autosize
          label=""
          type="textarea"
          placeholder=""
        />
      </div>
      <div class="flex align-center padding-sm padding-top">
        <div class="point bg-red margin-right-xs"></div>
        <div class="title text-df">附加</div>
      </div>
      <div
        class="padding-sm add"
        v-for="(item, index) in dimensionOne"
        :key="'type_one_b' + index"
      >
        <van-cell
          v-if="item.need_choose == 1"
          class="padding-lr-0"
          :border="false"
          :value="item.choose_user"
          :title="item.title"
        >
        </van-cell>
        <div v-else class="text-df response text-left padding-bottom-sm">
          {{ item.title }}
        </div>
        <div class="response text-sm text-left text-gray">
          {{ item.intro }}
        </div>
        <div class="flex answer response text-df text-left margin-top-sm">
          <van-field
            class="textarea"
            readonly
            v-model="item.reason"
            autosize
            label=""
            type="textarea"
            placeholder=""
          />
        </div>
      </div>
      <div class="flex align-center padding-sm padding-top">
        <div class="point bg-red margin-right-xs"></div>
        <div class="title text-df">邀请</div>
      </div>
      <div class="padding-sm flex align-center flex-direction justify-between">
        <div
          class="response flex align-center justify-between margin-bottom-xs"
        >
          <div class="flex justify-start flex-direction">
            <div class="title text-df">邀请他人对我评价</div>
          </div>
        </div>
        <div class="response text-sm text-left text-gray">
          邀请不超过三名同事对自己进行工作互助评价
        </div>
        <div class="response margin-top-sm flex justify-start">
          <van-tag
            v-for="(userItem, userIndex) in inviteUsers"
            :key="'user' + userIndex"
            class="margin-right-sm padding-sm"
            plain
            size="medium"
            type="primary"
          >
            {{ userItem.invite_user_name }}
          </van-tag>
        </div>
      </div>
    </div>
    <div v-if="active == 1">
      <div class="flex align-center justify-between padding-sm padding-top">
        <div class="flex align-center">
          <div class="point bg-red margin-right-xs"></div>
          <div class="title text-df">考核指标</div>
        </div>
        <div class="flex align-center">
          <van-rate allow-half :size="16" v-model="dimensionScore" readonly />
          <div class="text-lg score-width2" v-if="dimensionScore != null">
            {{ dimensionScore }}分
          </div>
        </div>
      </div>
      <div
        class="padding-sm flex align-center flex-direction justify-between"
        v-for="(item, index) in dimensionZero"
        :key="'type_two_a' + index"
      >
        <div
          class="response flex align-center justify-between margin-bottom-xs"
        >
          <div class="flex justify-start align-center">
            <div class="title text-df margin-right-sm">{{ item.title }}</div>
            <div class="text-df">{{ item.percent }}%</div>
          </div>
          <div class="flex align-center">
            <van-rate allow-half :size="14" v-model="item.score" readonly />
            <div class="text-df score-width2" v-if="item.score != null">
              {{ item.score }}分
            </div>
          </div>
        </div>
        <div class="response text-sm text-left text-gray">
          {{ item.intro }}
        </div>
        <div class="response solid padding-sm margin-top-sm">
          <div
            class="item margin-bottom-sm"
            v-for="(iitem, iindex) in item.details"
            :key="'type_two_a_details' + iindex"
          >
            <div
              class="response flex align-center justify-between margin-bottom-xs"
            >
              <div class="flex justify-start">
                <van-tag
                  :type="getInviteTypeText(iitem.invite_user_type).color"
                  class="margin-right-sm"
                  >{{ getInviteTypeText(iitem.invite_user_type).text }}</van-tag
                >
                <van-tag
                  v-if="iitem.is_leader == 1"
                  type="warning"
                  class="margin-right-sm"
                  >上级管理</van-tag
                >
                <div class="title text-df text-bold">{{ iitem.name }}</div>
              </div>
              <div class="flex align-center">
                <van-rate
                  allow-half
                  :size="12"
                  v-model="iitem.score"
                  readonly
                />
                <div class="text-df score-width" v-if="iitem.score != null">
                  {{ iitem.score }}分
                </div>
              </div>
            </div>
            <div class="flex justify-start align-center response">
              <div
                class="text-df margin-right-sm text-bold text-black"
                v-if="iitem.choose_user != null"
              >
                {{ iitem.choose_user }}
              </div>
              <van-field
                class="textarea"
                readonly
                v-model="iitem.reason"
                autosize
                label=""
                type="textarea"
                placeholder=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="flex align-center padding-sm padding-top">
        <div class="point bg-red margin-right-xs"></div>
        <div class="title text-df">附加</div>
      </div>
      <div
        class="padding-sm add"
        v-for="(item, index) in dimensionOne"
        :key="'type_two_b' + index"
      >
        <van-cell class="padding-lr-0" :border="false" :title="item.title">
        </van-cell>
        <div class="response text-sm text-left text-gray">
          {{ item.intro }}
        </div>
        <div class="response solid padding-sm margin-top-sm">
          <div
            class="item margin-bottom"
            v-for="(iitem, iindex) in item.details"
            :key="'type_two_b_details' + iindex"
          >
            <div
              class="response flex align-center justify-between margin-bottom-xs"
            >
              <div class="flex justify-start">
                <van-tag
                  :type="getInviteTypeText(iitem.invite_user_type).color"
                  class="margin-right-sm"
                  >{{ getInviteTypeText(iitem.invite_user_type).text }}</van-tag
                >
                <div class="title text-df text-bold">{{ iitem.name }}</div>
              </div>
            </div>
            <div class="flex justify-start align-center response">
              <div
                class="text-df margin-right-sm text-bold text-black"
                v-if="iitem.choose_user != null"
              >
                {{ iitem.choose_user }}
              </div>
              <van-field
                class="textarea"
                readonly
                v-model="iitem.reason"
                autosize
                label=""
                type="textarea"
                placeholder=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="active == 2">
      <div class="flex align-center padding-sm padding-top">
        <div class="point bg-red margin-right-xs"></div>
        <div class="title text-df">考核指标</div>
      </div>
      <div
        class="padding-sm flex align-center flex-direction justify-between"
        v-for="(item, index) in dimensionZero"
        :key="'type_two_a' + index"
      >
        <div
          class="response flex align-center justify-between margin-bottom-xs"
        >
          <div class="flex justify-start align-center">
            <div class="title text-df margin-right-sm">{{ item.title }}</div>
            <div class="text-df">{{ item.percent }}%</div>
          </div>
        </div>
        <div class="response text-sm text-left text-gray">
          {{ item.intro }}
        </div>
        <div class="response solid padding-sm margin-top-sm">
          <div
            class="item margin-bottom-sm"
            v-for="(iitem, iindex) in item.details"
            :key="'type_two_a_details' + iindex"
          >
            <div
              class="response flex align-center justify-between margin-bottom-xs"
            >
              <div class="flex justify-start">
                <van-tag
                  :type="getInviteTypeText(iitem.invite_user_type).color"
                  class="margin-right-sm"
                  >{{ getInviteTypeText(iitem.invite_user_type).text }}</van-tag
                >
                <div class="title text-df text-bold">{{ iitem.name }}</div>
              </div>
              <div class="flex align-center">
                <van-rate
                  allow-half
                  :size="12"
                  v-model="iitem.score"
                  readonly
                />
                <div class="text-df score-width" v-if="iitem.score != null">
                  {{ iitem.score }}分
                </div>
              </div>
            </div>
            <div class="flex justify-start align-center response">
              <div
                class="text-df margin-right-sm text-bold text-black"
                v-if="iitem.choose_user != null"
              >
                {{ iitem.choose_user }}
              </div>
              <van-field
                class="textarea"
                readonly
                v-model="iitem.reason"
                autosize
                label=""
                type="textarea"
                placeholder=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="flex align-center padding-sm padding-top">
        <div class="point bg-red margin-right-xs"></div>
        <div class="title text-df">附加</div>
      </div>
      <div
        class="padding-sm add"
        v-for="(item, index) in dimensionOne"
        :key="'type_two_b' + index"
      >
        <van-cell class="padding-lr-0" :border="false" :title="item.title">
        </van-cell>
        <div class="response text-sm text-left text-gray">
          {{ item.intro }}
        </div>
        <div class="response solid padding-sm margin-top-sm">
          <div
            class="item margin-bottom"
            v-for="(iitem, iindex) in item.details"
            :key="'type_two_b_details' + iindex"
          >
            <div
              class="response flex align-center justify-between margin-bottom-xs"
            >
              <div class="flex justify-start">
                <van-tag
                  :type="getInviteTypeText(iitem.invite_user_type).color"
                  class="margin-right-sm"
                  >{{ getInviteTypeText(iitem.invite_user_type).text }}</van-tag
                >
                <div class="title text-df text-bold">{{ iitem.name }}</div>
              </div>
            </div>
            <div class="flex justify-start align-center response">
              <div
                class="text-df margin-right-sm text-bold text-black"
                v-if="iitem.choose_user != null"
              >
                {{ iitem.choose_user }}
              </div>
              <van-field
                class="textarea"
                readonly
                v-model="iitem.reason"
                autosize
                label=""
                type="textarea"
                placeholder=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="active == 3">
      <div class="top">
        <div class="padding-sm text-left text-df"></div>
      </div>
      <van-tabs type="card" color="black">
        <van-tab :title="leader"></van-tab>
      </van-tabs>
      <div class="flex align-center justify-between padding-sm padding-top">
        <div class="flex align-center">
          <div class="point bg-red margin-right-xs"></div>
          <div class="title text-df">考核指标</div>
        </div>
        <div class="flex align-center">
          <van-rate allow-half :size="16" v-model="dimensionScore" readonly />
          <div class="text-lg score-width2" v-if="dimensionScore != null">
            {{ dimensionScore }}分
          </div>
        </div>
      </div>
      <div
        class="padding-sm flex align-center flex-direction justify-between"
        v-for="(item, index) in dimensionZero"
        :key="'type_one_a' + index"
      >
        <div
          class="response flex align-center justify-between margin-bottom-xs"
        >
          <div class="flex justify-start align-center">
            <div class="title text-df margin-right-sm">{{ item.title }}</div>
            <div class="text-df">{{ item.percent }}%</div>
          </div>
          <div class="flex align-center">
            <van-rate allow-half :size="16" v-model="item.score" readonly />
            <div class="text-df score-width" v-if="item.score != null">
              {{ item.score }}分
            </div>
          </div>
        </div>
        <div class="response text-sm text-left text-gray">
          {{ item.intro }}
        </div>
        <van-field
          class="textarea"
          readonly
          v-model="item.reason"
          autosize
          label=""
          type="textarea"
          placeholder=""
        />
      </div>
      <div class="flex align-center padding-sm padding-top">
        <div class="point bg-red margin-right-xs"></div>
        <div class="title text-df">附加</div>
      </div>
      <div
        class="padding-sm add"
        v-for="(item, index) in dimensionOne"
        :key="'type_one_b' + index"
      >
        <van-cell
          v-if="item.need_choose == 1"
          class="padding-lr-0"
          :border="false"
          :value="item.choose_user"
          :title="item.title"
        >
        </van-cell>
        <div v-else class="text-df response text-left padding-bottom-sm">
          {{ item.title }}
        </div>
        <div class="response text-sm text-left text-gray">
          {{ item.intro }}
        </div>
        <div class="flex answer response text-df text-left margin-top-sm">
          <van-field
            class="textarea"
            readonly
            v-model="item.reason"
            autosize
            label=""
            type="textarea"
            placeholder=""
          />
        </div>
      </div>
    </div>
    <div v-if="active == 4">
      <div class="flex align-center justify-between padding-sm padding-top">
        <div class="flex align-center">
          <div class="point bg-red margin-right-xs"></div>
          <div class="title text-df">考核指标</div>
        </div>
        <div class="flex align-center">
          <van-rate allow-half :size="16" v-model="dimensionScore" readonly />
          <div class="text-lg score-width2" v-if="dimensionScore != null">
            {{ dimensionScore }}分
          </div>
        </div>
      </div>
      <div
        class="padding-sm flex align-center flex-direction justify-between"
        v-for="(item, index) in dimensionZero"
        :key="'type_two_a' + index"
      >
        <div
          class="response flex align-center justify-between margin-bottom-xs"
        >
          <div class="flex justify-start align-center">
            <div class="title text-df margin-right-sm">{{ item.title }}</div>
            <div class="text-df">{{ item.percent }}%</div>
          </div>
          <div class="flex align-center">
            <van-rate allow-half :size="14" v-model="item.score" readonly />
            <div class="text-lg score-width2" v-if="item.score != null">
              {{ item.score }}分
            </div>
          </div>
        </div>
        <div class="response text-sm text-left text-gray">
          {{ item.intro }}
        </div>
        <div class="response solid padding-sm margin-top-sm">
          <div
            class="item margin-bottom-sm"
            v-for="(iitem, iindex) in item.details"
            :key="'type_two_a_details' + iindex"
          >
            <div
              class="response flex align-center justify-between margin-bottom-xs"
            >
              <div class="flex justify-start">
                <!-- <van-tag
                  :type="getInviteTypeText(iitem.invite_user_type).color"
                  class="margin-right-sm"
                  >{{ getInviteTypeText(iitem.invite_user_type).text }}</van-tag
                > -->
                <div class="title text-df text-bold">{{ iitem.name }}</div>
              </div>
              <div class="flex align-center">
                <van-rate
                  allow-half
                  :size="12"
                  v-model="iitem.score"
                  readonly
                />
                <div class="text-df score-width" v-if="iitem.score != null">
                  {{ iitem.score }}分
                </div>
              </div>
            </div>
            <div class="flex justify-start align-center response">
              <div
                class="text-df margin-right-sm text-bold text-black"
                v-if="iitem.choose_user != null"
              >
                {{ iitem.choose_user }}
              </div>
              <van-field
                class="textarea"
                readonly
                v-model="iitem.reason"
                autosize
                label=""
                type="textarea"
                placeholder=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="flex align-center padding-sm padding-top">
        <div class="point bg-red margin-right-xs"></div>
        <div class="title text-df">附加</div>
      </div>
      <div
        class="padding-sm add"
        v-for="(item, index) in dimensionOne"
        :key="'type_two_b' + index"
      >
        <van-cell class="padding-lr-0" :border="false" :title="item.title">
        </van-cell>
        <div class="response text-sm text-left text-gray">
          {{ item.intro }}
        </div>
        <div class="response solid padding-sm margin-top-sm">
          <div
            class="item margin-bottom"
            v-for="(iitem, iindex) in item.details"
            :key="'type_two_b_details' + iindex"
          >
            <div
              class="response flex align-center justify-between margin-bottom-xs"
            >
              <div class="flex justify-start">
                <van-tag
                  :type="getInviteTypeText(iitem.invite_user_type).color"
                  class="margin-right-sm"
                  >{{ getInviteTypeText(iitem.invite_user_type).text }}</van-tag
                >
                <div class="title text-df text-bold">{{ iitem.name }}</div>
              </div>
            </div>
            <div class="flex justify-start align-center response">
              <div
                class="text-df margin-right-sm text-bold text-black"
                v-if="iitem.choose_user != null"
              >
                {{ iitem.choose_user }}
              </div>
              <van-field
                class="textarea"
                readonly
                v-model="iitem.reason"
                autosize
                label=""
                type="textarea"
                placeholder=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-dialog
      @confirm="onConfirm"
      @cancel="onCancel"
      v-model="dialog_show"
      title="编辑人事评价"
      show-cancel-button
    >
      <van-form>
        <div class="flex flex-direction align-center margin-top padding">
          <div class="text-xl margin-bottom-sm text-bold">
            {{ scoreData.name }}
          </div>
          <div class="flex align-end margin-bottom-sm">
            <van-rate allow-half :size="24" v-model="scoreData.hr_score" />
            <div class="text-xl score-width" v-if="scoreData.hr_score != null">
              {{ scoreData.hr_score }}分
            </div>
          </div>
          <van-field
            class="border"
            v-model="scoreData.hr_memo"
            rows="2"
            autosize
            type="textarea"
            maxlength="100"
            placeholder="请输入评价理由"
            show-word-limit
          />
        </div>
      </van-form>
    </van-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import {
  getUserScoreByUserIdAction,
  getEvaluateDetailsAction,
  checkUserIsLeaderAction,
  updateHrScoreAction,
  getResultSettingsAction,
  resetAndMessageAction,
  exportPersonEvaluateAction,
} from "../api/evaluation.js";
import api from "../api/api";

import {
  Tab,
  Tabs,
  Divider,
  Cell,
  CellGroup,
  Rate,
  Field,
  Button,
  Tag,
  Icon,
  Sticky,
  Dialog,
  Form,
} from "vant";

Vue.use(CellGroup);
Vue.use(Cell);
Vue.use(Divider);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Rate);
Vue.use(Field);
Vue.use(Button);
Vue.use(Tag);
Vue.use(Divider);
Vue.use(Icon);
Vue.use(Sticky);
Vue.use(Dialog);
Vue.use(Form);

export default {
  name: "Home",
  components: {
    // Button,
    // DropdownMenu,
    // Popover,
    // BizCalendarChooseDateTime,
    // BizContactComplexPicker,
  },
  methods: {
    exportPersonEvaluate() {
      let result_id = this.result_id;
      let userid = this.scoreData.userid;
      let that = this;
      Dialog.confirm({
        title: "提示",
        message: "确定要导出数据?",
      })
        .then(() => {
          exportPersonEvaluateAction({
            userid: userid,
            result_id: result_id,
            type: 0,
          }).then((res) => {
            let errno = res.errno;
            window.open(this.root + res.data, "_blank");
            if (errno == 0) {
              that.$message({
                type: "success",
                message: "导出成功",
              });
            } else {
              that.$message({
                type: "error",
                message: res.errmsg,
              });
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goBack() {
      this.$router.push("/result");
    },
    onCancel() {},
    resetAndMessage() {
      let result_id = this.result_id;
      let userid = this.scoreData.userid;
      let that = this;
      Dialog.confirm({
        title: "提示",
        message: "确定要重启评价,并发消息给他/她?",
      })
        .then(() => {
          resetAndMessageAction({
            userid: userid,
            result_id: result_id,
          }).then((res) => {
            let errno = res.errno;
            if (errno == 0) {
              that.$message({
                type: "success",
                message: "更新成功",
              });
              that.checkUserIsLeader();
              that.getUserScoreByUserId();
              that.getEvaluateDetails();
              that.getResultSettings();
            } else {
              that.$message({
                type: "error",
                message: res.errmsg,
              });
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onConfirm() {
      let that = this;
      let scoreData = that.scoreData;
      let result_id = that.result_id;
      let hr_score = scoreData.hr_score;
      let hr_memo = scoreData.hr_memo;
      let userid = scoreData.userid;
      updateHrScoreAction({
        userid: userid,
        result_id: result_id,
        hr_score: hr_score,
        hr_memo: hr_memo,
      }).then((res) => {
        let errno = res.errno;
        let data = res.data;
        if (errno == 0) {
          that.$message({
            type: "success",
            message: "更新成功",
          });
          that.scoreData = data;
          that.getUserScoreByUserId();
        } else {
          that.$message({
            type: "error",
            message: res.errmsg,
          });
        }
      });
    },
    showDialogOne() {
      this.dialog_show = true;
    },
    getInviteTypeText(type) {
      let text = "指定";
      let color = "primary";
      // 类型,0指定,1邀约,2主管
      if (type == 0) {
        text = "指定";
        color = "primary";
      }
      if (type == 1) {
        text = "邀约";
        color = "success";
      }
      if (type == 2) {
        text = "主管";
        color = "warning";
      }
      return {
        text: text,
        color: color,
      };
    },
    getUserScoreByUserId() {
      let that = this;
      let userid = that.userid;
      let result_id = that.result_id;
      getUserScoreByUserIdAction({
        userid: userid,
        result_id: result_id,
      }).then((res) => {
        let errno = res.errno;
        let data = res.data;
        if (errno == 0) {
          that.scoreData = data;
        } else {
          this.$message({
            type: "error",
            message: res.errmsg,
          });
        }
      });
    },
    onClick(index) {
      let oldIndex = this.oldIndex;
      if (index != oldIndex) {
        this.getEvaluateDetails();
        this.oldIndex = index;
      }
    },
    getEvaluateDetails() {
      let that = this;
      let index = that.active;
      let userid = that.userid;
      let result_id = that.result_id;
      getEvaluateDetailsAction({
        index: index,
        userid: userid,
        result_id: result_id,
      }).then((res) => {
        let errno = res.errno;
        let data = res.data;
        if (errno == 0) {
          if (index == 0) {
            let inviteUsers = data.inviteUsers;
            that.inviteUsers = inviteUsers;
          } else if (index == 3) {
            that.leader = data.leader;
          }
          let evaluateData = data.evaluateData;
          that.dimensionScore = data.dimensionScore;
          that.dimensionZero = evaluateData.filter(
            (item) => item.dimension == 0
          );
          that.dimensionOne = evaluateData.filter(
            (item) => item.dimension == 1
          );
        } else {
          this.$message({
            type: "error",
            message: res.errmsg,
          });
        }
      });
    },
    checkUserIsLeader() {
      let that = this;
      let userid = that.userid;
      let result_id = that.result_id;
      checkUserIsLeaderAction({
        userid: userid,
        result_id: result_id,
      }).then((res) => {
        let errno = res.errno;
        let data = res.data;
        if (errno == 0) {
          that.isLeader = data;
        } else {
          this.$message({
            type: "error",
            message: res.errmsg,
          });
        }
      });
    },
    getResultSettings() {
      let result_id = this.result_id;
      getResultSettingsAction({
        result_id: result_id,
      }).then((res) => {
        let errno = res.errno;
        let data = res.data;
        if (errno == 0) {
          this.status = data.status;
        }
      });
    },
  },
  mounted() {
    let userid = this.$route.query.userid;
    let result_id = localStorage.getItem("result_id");
    this.userid = userid;
    this.result_id = result_id;
    this.checkUserIsLeader();
    this.getUserScoreByUserId();
    this.getEvaluateDetails();
    this.getResultSettings();
  },
  data() {
    return {
      status: 0,
      active: 0,
      dialog_show: false,
      scoreData: {},
      result_id: 0,
      userid: 0,
      hrScore: {
        hr_memo: "",
      },
      dimensionZero: [],
      dimensionOne: [],
      inviteUsers: [],
      leader: "",
      isLeader: 0,
      dimensionScore: 0,
      root: api.root,
    };
  },
};
</script>

<style scoped lang="scss">
.rank {
  width: 8px;
  height: 16px;
}

.back {
  cursor: pointer;
}
.screen {
  max-width: 600px;
  margin: auto;
  .score-width {
    width: 40px;
  }
  .score-width2 {
    width: 50px;
  }
}
::v-deep .van-cell__title {
  display: flex;
  justify-content: flex-start;
}
.border {
  margin-top: 8px;
  border: 1px solid #d4d4d4;
}
.textarea {
  border: none;
  background: #f8f8f8;
}
</style>